//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}

.table-modern > tbody > tr > *,
.table-modern > thead > tr > * {
	padding: 0.6rem 0.3rem;
	white-space: nowrap;
	overflow: hidden;
}

.ghostboardSpecial {
	background: linear-gradient(0.25turn, #3f3d90, #7a3d91, #0f3469);
}

.ghostboardTextSpecial {
    /* Gradient background */
	background: linear-gradient(0.25turn, #9492d7, #9762aa, #5683c2);

    /* Making the text color transparent */
    -webkit-text-fill-color: transparent; /* For WebKit browsers */
    color: transparent; /* Standard method, works in most modern browsers */

    /* Clipping the background to the text */
    -webkit-background-clip: text; /* For WebKit browsers */
    background-clip: text;

    /* Optional: Adjusting the display property for better gradient handling */
    display: inline-block;
}

/* React Select -- Inline Modifier
* Inline container with margin for better inline spacing between other elements 
*/
.react-select--inline {
	display: inline-block;
	margin: 0 0.25em;
}

/* Remove border, outline, box-shadow, and min-height values */
.react-select--inline .react-select__control {
	border: none;
	outline: none;
	box-shadow: none;
	min-height: none;
	cursor: pointer;
}

/* Tighten up spacing */
.react-select--inline .react-select__value-container {
	padding: 0;
}

/* Position value relative (instead of absolute) and remove transform and max-width */
.react-select--inline .react-select__single-value {
	font-weight: bold;
	position: relative;
	transform: none;
	max-width: none;
}

/* CSS triangle dropdown indicator after the selected value */
.react-select--inline .react-select__single-value::after {
	content: ' ';
	position: relative;
	display: inline-block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 5px 4px 0 4px;
	border-color: #000000 transparent transparent transparent;

	margin-left: 0.25em;
	top: -0.125em;
}

.specialGhostboardUpdate {
	//background-color: ;
}
